<template>
  <tj-dialog v-model="visible" title="修改结算方式" width="300px">
    <el-form ref="form" label-width="auto" :model="detail" :rules="rules">
      <el-form-item label="结算类型：">
        <el-radio-group v-model="detail.type" class="va-m">
          <el-radio-button :label="1">普通</el-radio-button>
          <el-radio-button :label="2">包月</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="detail.type === 2"
        label="包月费用："
        prop="money"
      >
        <el-input v-model.trim="detail.money"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </template>
  </tj-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { useDialog } from '@tj/ui';
import request from '@/hooks/request';

const form = ref();
const rules = {
  money: { required: true, message: '包月费用不能为空' },
};

const detail = reactive({
  company_id: 0,
  type: 1,
  money: '',
});

const {
  visible,
  open,
  close,
  onOpen,
} = useDialog();
defineExpose({ open });
onOpen(({
  id,
  settlement_type: settlementType,
  money,
}) => {
  detail.company_id = id;
  detail.type = settlementType;
  detail.money = money;
});

const emit = defineEmits(['submit']);

function submit() {
  form.value.validate((valid) => {
    if (valid) {
      request.post('/admin/company/billingType', detail).then(() => {
        close();
        emit('submit');
      });
    }
  });
}
</script>

<template>
  <tj-dialog
    v-model="visible"
    title="审核"
    @close="onClose"
  >
    <el-form v-if="detail" ref="formRef" label-width="auto">
      <el-form-item label="公司名称：">
        {{ detail.company_name }}
      </el-form-item>
      <el-form-item label="省份：">
        {{ detail.province }}
      </el-form-item>
      <el-form-item label="城市：">
        {{ detail.city }}
      </el-form-item>
      <el-form-item label="区：">
        {{ detail.area }}
      </el-form-item>
      <el-form-item label="认证资料：">
        <el-image :src="detail.cerify_image"></el-image>
      </el-form-item>
      <el-form-item label="结算方式：">
        <template v-if="detail.settlement_type === 1">月结</template>
        <template v-if="detail.settlement_type === 2">包月（费用：{{ detail.money }}）</template>
      </el-form-item>
      <el-form-item label="审核：">
        <el-button
          v-if="detail.is_pass === 0 || detail.is_pass === 1"
          type="success"
          @click="review(true)"
        >通过</el-button>
        <el-button
          v-if="detail.is_pass === 0 || detail.is_pass === 2"
          type="danger"
          @click="review(false)"
        >拒绝</el-button>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取消</el-button>
    </template>
  </tj-dialog>
</template>

<script setup>
import { ref } from 'vue';
import { ElMessageBox } from 'element-plus';
import { useDialog } from '@tj/ui';
import request from '@/hooks/request';

const emit = defineEmits(['submit']);

const detail = ref(null);

const {
  visible,
  open,
  close,
  onOpen,
} = useDialog();
onOpen((data) => {
  detail.value = { ...data };
});
function onClose() {
  detail.value = null;
}

function submit(isPass, cause) {
  const params = {
    company_id: detail.value.id,
    is_pass: isPass ? 2 : 1,
  };
  if (cause) {
    params.cause = cause;
  }
  request.post('/admin/company/review', params).then(() => {
    emit('submit');
    close();
  });
}

function review(isPass) {
  if (isPass) {
    submit(true);
  } else {
    ElMessageBox.prompt('请输入拒绝原因', '提示', {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      inputPattern: /.+/,
      inputErrorMessage: '拒绝原因不能为空',
    }).then(({ value }) => {
      submit(false, value);
    });
  }
}

defineExpose({
  open,
});
</script>

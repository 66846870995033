<template>
  <div class="app-wrap">
    <el-container class="app-container">
      <el-main>
        <el-table :data="tableData">
          <el-table-column label="序号" type="index" align="right"></el-table-column>
          <el-table-column label="公司名称" prop="company_name"></el-table-column>
          <el-table-column label="地区" prop="province"></el-table-column>
          <el-table-column label="结算方式" align="center" width="200">
            <template #default="{ row }">
              <el-button
                type="text"
                :icon="Edit"
                @click="changeSettlementType(row)"
              >
                <template v-if="row.settlement_type === 0">未设置</template>
                <template v-if="row.settlement_type === 1">月结</template>
                <template v-if="row.settlement_type === 2">包月（费用：{{ row.money }}）</template>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center" width="80">
            <template #default="{ row }">
              <el-tag v-if="row.is_pass === -1" type="info">禁用</el-tag>
              <el-tag v-if="row.is_pass === 0" type="warning">待审核</el-tag>
              <el-tooltip v-if="row.is_pass === 1" placement="top" :content="row.cause">
                <el-tag type="danger">未通过</el-tag>
              </el-tooltip>
              <el-tag v-if="row.is_pass === 2" type="success">通过</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="300">
            <template #default="{ row }">
              <el-button
                type="text"
                :icon="View"
                @click="openDetail(row)"
              >审核</el-button>
              <el-button
                type="text"
                :icon="Management"
                @click="setManagement(row)"
              >设置管理员</el-button>
              <el-button
                type="text"
                :icon="Avatar"
                @click="staff(row.id)"
              >员工列表</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer height="auto">
        <tj-pagination
          v-model="page"
          @change="getList"
        ></tj-pagination>
      </el-footer>
    </el-container>
  </div>
  <EditDialog ref="editDialog" @submit="getList"></EditDialog>
  <SettlementType ref="settlementType" @submit="getList"></SettlementType>
  <Manager ref="manager" @submit="getList"></Manager>
  <StaffList ref="staffList"></StaffList>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import {
  View,
  Edit,
  Management,
  Avatar,
} from '@element-plus/icons-vue';
import { usePagination } from '@tj/ui';
import EditDialog from './edit.vue';
import request from '@/hooks/request';
import SettlementType from './SettlementType.vue';
import Manager from './Manager.vue';
import StaffList from './StaffList/Index.vue';

const { page } = usePagination();
const tableData = ref([]);

function getList() {
  request.get('/admin/order/companyList', {
    params: {
      page: page.page,
      per_page: page.limit,
    },
  }).then((data) => {
    tableData.value = data.list;
    page.total = data.total;
  });
}

const editDialog = ref();

function openDetail(brandId) {
  editDialog.value.open(brandId);
}

const settlementType = ref();
function changeSettlementType(data) {
  settlementType.value.open(data);
}

const manager = ref();
function setManagement(data) {
  manager.value.open(data);
}

const staffList = ref();
function staff(id) {
  staffList.value.open(id);
}

onMounted(() => {
  getList();
});
</script>
